import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import PostListItem from './PostListItem';

class PostList extends React.Component {
  render() {
    return (
      <StaticQuery
        query={postListQuery}
        render={data => {
          // Destructure query
          const { posts, covers } = data;

          // Create Post elements
          const postElements = posts.edges.map((p, i) => {
            // Find cover based on slug
            const post = p.node;
            const slug = post.fields.slug.split('/')[2];
            const cover = covers.edges.filter(
              c => c.node.relativeDirectory === slug
            )[0].node.childImageSharp;

            return (
              <PostListItem
                key={i}
                slug={post.fields.slug}
                cover={cover}
                title={post.frontmatter.title}
                tags={post.frontmatter.tags}
              />
            );
          });

          return (
            <div className="columns is-multiline postlist">{postElements}</div>
          );
        }}
      />
    );
  }
}

const postListQuery = graphql`
  query postListQuery {
    posts: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tags
          }
        }
      }
    }
    covers: allFile(filter: { name: { eq: "cover" } }) {
      edges {
        node {
          relativeDirectory
          childImageSharp {
            fixed(width: 600, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;

export default PostList;
