import React from 'react';
import { Link } from 'gatsby';

class PostListItem extends React.Component {
  constructor() {
    super();

    this.state = {
      layoverIsVisible: false,
    };

    this.showLayover = this.showLayover.bind(this);
    this.hideLayover = this.hideLayover.bind(this);
  }

  showLayover() {
    this.setState({
      layoverIsVisible: true,
    });
  }

  hideLayover() {
    this.setState({
      layoverIsVisible: false,
    });
  }

  render() {
    const { slug, cover, title, tags } = this.props;

    // Conditional rendering of the layover
    let layoverClass;
    if (this.state.layoverIsVisible) {
      layoverClass = 'layover layover-active';
    } else {
      layoverClass = 'layover';
    }

    return (
      <div className="column is-half postlistitem">
        <Link to={`${slug}`}>
          <div
            className="container"
            onMouseEnter={this.showLayover}
            onMouseLeave={this.hideLayover}
            onTouchStart={this.showLayover}
            onTouchEnd={this.hideLayover}
            style={{
              background: `url('${cover.fixed.src}')`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className={layoverClass}>
              <div className="layover-content">
                <h1>{title}</h1>
                <small>{tags}</small>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

export default PostListItem;
